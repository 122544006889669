interface IP2LocationData {
  request_id: string
  status: string
  code: string
  message: string
  data: Array<{
    ip_info: {
      ip: string
      version: number
      use_vpn: false
    }
    geo_info: {
      latitude: number
      longitude: number
      country: {
        name: string
        iso2_code: string
        prohibited: boolean
        ip_limit: boolean
      }
      state: {
        name: string
        prohibited: boolean
      }
    }
  }>
  metadata: {}
}
export default defineNuxtRouteMiddleware(async (event) => {
  if (import.meta.server) {
    const { ip2LocationToken, public: { apiHost } } = useRuntimeConfig()
    if (ip2LocationToken) {
      const ip = useRequestHeader('x-forwarded-for')
      const { data, status, code, message, metadata } = await $fetch<IP2LocationData>(
        `https://${apiHost}/v2/app/ip?ip=${ip?.split(',')[0]}`,
        {
          headers: {
            Authorization: `Bearer ${ip2LocationToken}`,
          },
        },
      )
      if (status === 'success') {
        if (data.length) {
          const { geo_info } = data[0]
          if (geo_info?.country?.ip_limit) {
            throw createError({
              statusCode: 405,
              statusText: code,
              message: `You are accessing Legend Trading services from ${geo_info?.country.name}. <br>
              Due to compliance reasons, we are unable to offer services to users from this region.`,
            } as any)
          }
        }
      }
    }
  }
})
